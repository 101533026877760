import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { FormikErrors, FormikProps } from 'formik';
import { produce } from 'immer';
import React, { useCallback, useMemo } from 'react';

import { Alert, Box, Button, EntryList, Flex, Icon, Modal, Tooltip } from '~/components/blocks';
import {
  LineItemField,
  OptionalLineItem,
  OptionalLineItemField,
  SimpleWebVisitorProfile,
  TotalAmount,
} from '~/components/partials';
import { Charge } from '~/constants/charge';
import {
  AppointmentDeliveryMethod,
  CompanyFeeSettingFragment,
  LineItemCode,
  OrganizationCompanyFeeSettingFragment,
  OrganizationFeeSettingFragment,
  WebAppointmentForChargeFragment,
} from '~/graphql';
import { SystemAmount } from '~/hooks/use-system_amount';
import { Label } from '~/utils/label';

import { ChargeNoAmountCheckBox } from '../ChargePanel/ChargeNoAmountCheckbox';
import { PaymentMethodBox } from '../ChargePanel/PaymentMethod/PaymentMethodBox';
import { DeliveryMethodField } from './DeliveryMethodField';
import { Fields } from './types';

type Props = {
  appointment: WebAppointmentForChargeFragment;
  formik: FormikProps<Fields>;
  feeSettings: (
    | OrganizationFeeSettingFragment
    | CompanyFeeSettingFragment
    | OrganizationCompanyFeeSettingFragment
  )[];
  needsDeliveryMethod: boolean;
  systemAmount: SystemAmount;
  onConfirm: () => void;
};

const ButtonAdd = styled(
  ({
    className,
    disabled,
    onClick,
  }: {
    className?: string;
    disabled: boolean;
    onClick?: () => void;
  }) => (
    <button className={className} disabled={disabled} onClick={onClick}>
      <Icon icon="plus" size="l" />
      項目追加
    </button>
  ),
)(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    background: theme.colors.background.default,
    border: `1px dashed ${theme.colors.border.default}`,
    padding: `${theme.space.m} ${theme.space.l}`,
    borderRadius: theme.radii.default,
    color: theme.colors.text.default,
    lineHeight: theme.lineHeights.s,
    outline: 'none',
    transitionDuration: theme.transitions.fast,
    '&:hover': {
      background: theme.colors.background.bg,
      cursor: 'pointer',
    },
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);

const ButtonAddWrapper = styled('span')(({ theme }) =>
  css({
    display: 'inline-block',
    marginTop: theme.space.m,
  }),
);

const ButtonDelete = styled('button')(({ theme }) =>
  css({
    display: 'flex',
    width: '30px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.background.bg,
    border: theme.borders.transparent,
    borderRadius: theme.radii.circle,
    marginTop: '10px',
    marginLeft: theme.space.l,
    outline: 'none',
    transitionDuration: theme.transitions.fast,
    '&:hover': {
      background: theme.colors.background.default,
      border: theme.borders.default,
      cursor: 'pointer',
    },
  }),
);

const getTotalAmount = (values: Fields) => {
  return [values.lineItem, ...values.optionalLineItems].reduce(
    (result, { amount = 0 }) => result + +amount,
    0,
  );
};

export const InputPanel = React.memo((props: Props) => {
  const { formik, onConfirm } = props;
  const theme = useTheme();
  const optionalFeeSettings = props.feeSettings.filter(({ fixed }) => !fixed);
  const existedFeeSetting = optionalFeeSettings.length > 0;

  const isInvalidAmountWithCash = useMemo(() => {
    const withCash = formik.values.paymentMethod === 'cash';
    const totalAmount = getTotalAmount(formik.values);
    return withCash && Charge.maxAmount < totalAmount;
  }, [formik.values]);

  const handleConfirm = useCallback(async () => {
    const error = await formik.validateForm();
    const isValid = !error || Object.keys(error).length === 0;

    if (!isInvalidAmountWithCash && isValid) {
      onConfirm();
    }
  }, [formik, isInvalidAmountWithCash, onConfirm]);

  const handleCheckIsNoAmount = useCallback(() => {
    const newValue = !formik.values.isNoAmount;
    formik.setFieldValue('isNoAmount', newValue);
    const isNoAmount = newValue;

    if (isNoAmount) {
      formik.setFieldValue('lineItem', {
        code: LineItemCode.BillingAmount,
        subject: '患者負担金',
        amount: 0,
      });
      formik.setFieldValue('optionalLineItems', []);
      formik.setFieldValue('deliveryMethod', AppointmentDeliveryMethod.Hand);
    } else {
      formik.setValues(formik.initialValues);
    }
  }, [formik]);

  return (
    <>
      <Modal.Body>
        <SimpleWebVisitorProfile visitor={props.appointment.webVisitor} />
        {isInvalidAmountWithCash && (
          <Alert status="error">
            窓口決済の場合は、合計金額が{Label.amount(Charge.maxAmount)}
            以下になるように金額を入力してください
          </Alert>
        )}
        <EntryList marginTop={theme.space.l}>
          <EntryList.Head>支払い方法</EntryList.Head>
          <EntryList.Body>
            <PaymentMethodBox paymentMethod="cash" />
          </EntryList.Body>
        </EntryList>
        {formik.values.paymentMethod === 'cash' && (
          <ChargeNoAmountCheckBox
            checked={formik.values.isNoAmount}
            onChange={handleCheckIsNoAmount}
          />
        )}
        {!formik.values.isNoAmount && (
          <EntryList marginTop={theme.space.l}>
            <EntryList.Head>請求金額</EntryList.Head>
            <EntryList.Body>
              <Box>
                <LineItemField
                  values={formik.values.lineItem}
                  errors={formik.errors.lineItem}
                  onChange={(newValues) => {
                    formik.setFieldValue('lineItem', newValues);
                  }}
                />
                {formik.values.optionalLineItems.map((lineItem, idx) => (
                  <Flex key={idx} mt={theme.space.m}>
                    <OptionalLineItemField
                      feeSettings={optionalFeeSettings}
                      values={lineItem}
                      errors={
                        (
                          formik.errors.optionalLineItems as
                            | FormikErrors<OptionalLineItem>[]
                            | undefined
                        )?.[idx]
                      }
                      onChange={(_values) => {
                        const newLineItems = produce(formik.values.optionalLineItems, (draft) => {
                          draft[idx] = _values;
                        });
                        formik.setFieldValue('optionalLineItems', newLineItems);
                      }}
                    />
                    <ButtonDelete
                      onClick={() => {
                        const newLineItems = formik.values.optionalLineItems.filter(
                          (_, i) => i !== idx,
                        );
                        formik.setFieldValue('optionalLineItems', newLineItems);
                      }}
                    >
                      <Icon icon="close2" size="xl" />
                    </ButtonDelete>
                  </Flex>
                ))}
                {existedFeeSetting ? (
                  <ButtonAddWrapper>
                    <ButtonAdd
                      disabled={!existedFeeSetting}
                      onClick={() =>
                        formik.setFieldValue('optionalLineItems', [
                          ...formik.values.optionalLineItems,
                          {
                            feeSettingId: '',
                            code: '',
                            subject: '',
                            amount: '',
                          },
                        ])
                      }
                    />
                  </ButtonAddWrapper>
                ) : (
                  <Tooltip placement="top" content="項目が登録されていないため追加できません">
                    <ButtonAddWrapper>
                      <ButtonAdd disabled />
                    </ButtonAddWrapper>
                  </Tooltip>
                )}
              </Box>
            </EntryList.Body>
          </EntryList>
        )}
        <TotalAmount lineItems={[formik.values.lineItem, ...formik.values.optionalLineItems]} />
        {props.needsDeliveryMethod && (
          <DeliveryMethodField
            value={formik.values.deliveryMethod}
            onChange={(deliveryMethod) =>
              formik.setFieldValue('deliveryMethod', deliveryMethod || null)
            }
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" onClick={handleConfirm}>
          確認
        </Button>
      </Modal.Footer>
    </>
  );
});

InputPanel.displayName = 'InputPanel';
