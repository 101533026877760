import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { WebAppointmentForChargeFragment } from '~/graphql';
import { useB2cloud } from '~/hooks/use-b2cloud';
import { useSystemAmount } from '~/hooks/use-system_amount';
import { webVisitorChargeModalState } from '~/state/reception/atoms';

import { ConfirmPanel } from './ConfirmPanel';
import { InputPanel } from './InputPanel';
import { Fields } from './types';
import { useCreateInitialValues } from './use-create_initialvalues';
import { useFetchFeeSettings } from './use-fetch-fee_settings';
import { validationSchema } from './validation';

type Props = {
  appointment: WebAppointmentForChargeFragment;
};

export const WebVisitorChargePanel = (props: Props) => {
  const { appointment } = props;
  const state = useRecoilValue(webVisitorChargeModalState);
  const [isConfirm, setIsConfirm] = useState(false);
  const formikRef = useRef<FormikProps<Fields>>(null);
  const resetState = useResetRecoilState(webVisitorChargeModalState);

  const feeSettings = useFetchFeeSettings();

  const systemAmount = useSystemAmount(state.appointmentId);
  const { enabledB2cloud } = useB2cloud();
  const initialValues = useCreateInitialValues(appointment, feeSettings, systemAmount);
  const needsDeliveryMethod = !!appointment?.telemedicine && !!enabledB2cloud;
  const handleConfirm = useCallback(() => setIsConfirm(true), []);
  const handleBack = useCallback(() => setIsConfirm(false), []);

  useEffect(() => {
    if (!state.isOpen) {
      formikRef.current?.resetForm();
      setIsConfirm(false);
      resetState();
    }
  }, [resetState, state.isOpen]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleConfirm}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) =>
        !isConfirm ? (
          <InputPanel
            formik={formik}
            appointment={appointment}
            needsDeliveryMethod={needsDeliveryMethod}
            feeSettings={feeSettings}
            systemAmount={systemAmount}
            onConfirm={handleConfirm}
          />
        ) : (
          <ConfirmPanel
            appointment={appointment}
            needsDeliveryMethod={needsDeliveryMethod}
            values={formik.values}
            onBack={handleBack}
          />
        )
      }
    </Formik>
  );
};
